#home {
  position: relative;
  background-image: url("../../assets/ladn3.jpg");

  background-size: cover;

  .app__wrapper {
    padding: 0;

    .copyright {
      display: none;
    }
  }
}

.app__header {
  display: flex;
  flex: 1;
  width: 80%;
  height: 100%;
  flex-direction: row;
  padding: 6rem 2rem 0;
  justify-content: space-evenly;
  align-items: center;
  // background-color: blue;

  @media screen and (min-width: 2000px) {
    padding-top: 8rem;
  }
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 450px) {
    padding-top: 6rem 1rem 2rem;
  }
}

.app__header-info {
  flex: 0.65;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-width: 50%;
  // background-color: red;

  @media screen and(max-width:2000px) {
    width: 100%;
    margin-right: 0rem;
  }
}

.app__header-badge {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .badge-cmp,
  .tag-cmp {
    padding: 1rem 2rem;
    border: var(--white-color);
    border-radius: 15px;
    flex-direction: row;
    width: auto;
    // box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    p {
      font-size: 38px;
    }
    h1 {
      font-size: 66px !important;
      color: var(--white-color);
    }
  }

  .tag-cmp {
    flex-direction: column;
    margin-top: 3rem;

    p {
      width: 100%;
      text-transform: uppercase;
      text-align: right;
    }
  }

  span {
    font-size: 2.5rem;
    @media screen and(min-width:2000px) {
      font-size: 5rem;
    }
  }

  @media screen and(max-width:1200px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.app__header-img {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: relative;
  max-width: 50%;
  // background-color: green;

  img {
    position: relative;
    width: 65%;
    object-fit: contain;
    z-index: 1;
    right: 150px;
    bottom: 80px;
  }

  .overlay_circle {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    z-index: 0;
    width: 100%;
    height: 90%;
  }

  @media screen and(max-width:1200px) {
    margin: 2rem 0;

    img {
      right: 40px;
    }
    // color: #090a20bd;
    // color: #36107cbd;
  }
}
