.GuideAbilities {
  width: 70%;
  // margin: 0 auto;
  background-color: var(--gray-color);
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // border-radius: 10px;
  // z-index: 10;

  h2 {
    width: 95%;
    // background-color: red;
    text-align: left;
    font-size: 18px;
    margin: 1rem auto;
    color: var(--black-color);
    text-transform: uppercase;
  }
  @media screen and(max-width:1920px) {
    width: 100%;
  }

  .spell-empty {
    @media screen and(max-width:1200px) {
      width: 0 !important;
      height: 0 !important;
      padding: 0 !important;
      border: 0 !important;
      margin: 0 !important;
    }
  }
}

.Abilities {
  border-radius: 10px;
}

.Spellls {
  width: 100%;
  background-color: var(--gray-color);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  //   align-items: flex-start;
  //   justify-content: flex-start;

  .Passive {
    justify-content: flex-start;
  }

  .Spelll {
    justify-content: center;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .Spell-abilities {
    margin: 0 2rem;
  }
}
