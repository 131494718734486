.GuideInfo {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  // background-color: green;
}

.GuideInfo-image {
  width: 100%;
  margin: 2rem auto;
  img {
    width: 300px;
    border-radius: 10px;
    border: 3px solid var(--gray-color);
  }

  .GuideInfo-text {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 33px;
    position: relative;
    background: var(--primary-color);
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 11px;
    padding: 6px 6px;
    margin-bottom: 5px;

    // h2 {
    //   position: relative;
    //   top: -50px;
    //   left: -400px;
    // }
  }
}

.GuideInfo-container {
  width: 100%;

  .GuideInfo-desc {
    // background-color: var(--primary-color);
    min-height: 100px;
    padding: 1rem;
    border-radius: 10px;
    width: 93%;
    margin: 0.5rem;
  }
}
