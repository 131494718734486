@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Mono:wght@500&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #3b4f7d;
  --secondary-color: #6c7b9e;

  /* --secondbg-color: #0d112a; */

  --black-color: #5a6be3;
  /* --thirdbg-color: rgba(2, 2, 2, 0.788); */
  /* --lightGray-color: #e4e4e4; */
  --gray-color: #d2ddff;
  --brown-color: #ffba08;
  --white-color: #e9eef1;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
