.app__champs {
  flex: 1;
  width: 85%;
  // background-color: red;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 3.5rem;
  }
}

.app__champ-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Champions-h2 {
  // background-color: green;
  width: 100%;
  h2 {
    font-size: 32px;
    color: var(--black-color);
  }
  span {
    color: var(--primary-color);
  }
}

.app__champ-input {
  // padding: 4rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1rem 2rem;
  input {
    width: 100%;
    padding: 0.95rem;
    border: none;
    border-radius: 7px;
    background-color: var(--gray-color);
    font-family: var(--font-base);
    color: var(--black-color);
    outline: none;
  }

  @media screen and(max-width: 768px) {
    flex-direction: column;
    input {
      width: 100%;
    }
  }
}
