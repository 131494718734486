.Footer {
  height: 400px;
  display: flex;
  //   align-items: bottom;
  justify-content: space-between;
  background-color: var(--primary-color);
  width: 100%;
  // margin-top: 5rem;
  img {
    position: relative;
    height: 550px;
    // width: 40%;
    // background-color: red;
    bottom: 150px;

    @media screen and(max-width:500px) {
      // width: 100%;
      height: 200px;
      bottom: 0px;
      width: 0%;
    }
  }

  @media screen and(max-width:500px) {
    width: 100%;
    height: 200px;
  }

  .Footer-div {
    // background-color: red;
    width: 50%;
    margin: 4rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: var(--white-color);
      text-align: center;
    }

    p {
      color: var(--gray-color);
      text-align: center;
    }

    h5 {
      text-align: center;
      color: var(--gray-color);
    }
  }
}
