.GuideInfoItems {
  width: 100%;
  height: 70%;
  // display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  background-color: var(--gray-color);
  // background-image: url("../../../../assets/Yone-blur.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-clip: clip;
  align-items: center;
  justify-content: space-evenly;
  // border-radius: 10px;

  h2 {
    text-align: left;
    padding: 1rem;
    font-size: 18px;
    text-transform: uppercase;
    color: var(--black-color);
  }

  .GuideInfoItems-flex {
    // background-color: red;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
