.GuideChamps {
  width: 100%;
  // height: 100%;
  display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // margin: 1rem 0;
  align-items: top;
  justify-content: space-between;
  // background-color: green;

  h2 {
    text-align: center;
    padding: 0.5rem 0;
    font-size: 18px;
  }
  p {
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--primary-color);
    display: inline-block;
    vertical-align: middle;
    margin: 0.9rem 0;
  }
}

.GuideChamps-champs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: var(--gray-color);
  // border-radius: 10px;
  // margin: 2rem 0;
  // padding: 1rem;
}

.GuideChamps-item {
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  p {
    text-align: center;
  }
  img {
    border-radius: 50%;
    height: 75px;
    width: 75px;
    border: 2px solid rgb(0, 252, 0);
  }
}

.syn {
  img {
    border: 2px solid red;
  }
}

.GuideChamps-yeet {
  display: flex;
  align-items: top;
  justify-content: space-between;
  // flex-direction: column;
  width: 100%;

  @media screen and(max-width:1300px) {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
  }

  .GuideChamps-div {
    background-color: var(--gray-color);
    // border-radius: 10px;
    width: 49%;
    margin-bottom: 1rem;

    @media screen and(max-width:1300px) {
      width: 100%;
    }

    h2 {
      text-align: center;
      font-size: 18px;
      line-height: 14px;
      text-transform: uppercase;
      color: var(--black-color);
      // display: inline-block;
      vertical-align: middle;
      margin-top: 1rem;
      font-weight: 800;
      // padding: 0 1rem;
    }
  }

  div {
    // background-color: red;
  }
}
