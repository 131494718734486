.GuideInfoSet {
  font-size: 14px;
  //   margin-bottom: 13px;
  display: inline-block;
  // margin-right: 2rem;
  // margin: 1rem auto;
  // min-width: 45%;
  // border-radius: 10px;

  margin: 0;
  // background: #b0c3fd;
  // background-image: url("../../../../../assets/ahri4.png");

  h2 {
    font-size: 18px;
    line-height: 14px;
    text-transform: lowercase;
    color: var(--primary-color);
    // display: inline-block;
    vertical-align: middle;
    // margin-bottom: 0.5rem;
    // margin: 0.5rem 1rem;
    text-align: center;
  }
  .GuideInfoSet-items {
    // background: var(--primary-color);
    // border-radius: 10px;
    // padding: 1rem;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-bottom: 0.5rem;

    .item {
      display: inline-block;
      vertical-align: top;
      width: 59px;
      position: relative;
      margin: 0 7px 14px;

      img {
        width: 46px;
        height: 46px;
        border: 2px solid var(--black-color);
        border-radius: 2px;
        display: block;
        margin: 0 auto;
      }
      p {
        margin-top: 3px;
        color: var(--primary-color);
        font-size: 12px;
        line-height: 12px;
        display: block;
        text-align: center;
        word-break: break-word;
      }
    }
  }
}
