.Comments-main {
  width: 90%;
  margin: 2rem auto;
  background-color: var(--primary-color);
  // background-image: url("../../assets/Yone-blur.jpg");
  h2 {
    padding: 1rem;
    color: var(--white-color);
    text-align: center;
    font-size: 28px;
  }
  // border-radius: 10px;
}

.Comments-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  //   width: 80%;

  .Comments-input {
    padding: 2rem 0;
    width: 80%;
    // display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;
    input {
      width: 100%;
      padding: 0.95rem;
      border: none;
      // border-radius: 7px;
      font-family: var(--font-base);
      background-color: var(--gray-color);
      color: var(--primary-color);
      outline: none;
    }

    @media screen and(max-width: 768px) {
      flex-direction: column;
      input {
        width: 100%;
      }
    }
  }
}
