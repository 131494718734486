.ChampionCard-item {
  width: 180px;
  height: 240px;
  flex-direction: column;
  margin: 2rem;
  padding: 1rem;
  border-radius: 0.5rem;
  // background-color: #090a20;
  // background-color: #d9e0e4;
  background-color: var(--gray-color);
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
  }

  @media screen and(min-width: 2000px) {
    width: 470px;
    padding: 1.25rem;
    border-radius: 0.75rem;
  }

  @media screen and(max-width: 600px) {
    width: 140px;
    height: 180px;
    margin: 1rem;
    // background-color: red;
  }
}

.ChampionCard-image {
  width: 100%;
  height: 148px;
  position: relative;
  justify-content: center;
  margin: auto;
  align-items: center;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and(min-width: 2000px) {
    height: 350px;
  }

  @media screen and(max-width: 600px) {
    height: 110px;
  }
}

.ChampionCard-hover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transition: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;

    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}

.ChampionCard-content {
  padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    margin-top: 1rem;
    line-height: 1.5;
  }

  .ChampionCard-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    // background-color: #090a20;
    background-color: var(--gray-color);
    top: -25px;
  }
}
