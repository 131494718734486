.app__auths {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__auth-input {
  padding: 4rem 0;
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: var(--gray-color);
  margin: 1rem auto;
  border-radius: 10px;
  // background-image: url("../../assets/Yasuo_36-blur.png");
  background-color: var(--primary-color);
  @media screen and (max-width: 768px) {
    width: 100%;
  }

  form {
    width: 80%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;

    .app__auth-form-div {
      // background-color: red;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;

      div {
        width: 50%;
        // background: green;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;

        p {
          cursor: pointer;
          color: var(--white-color);
        }
      }
    }
  }
  input {
    width: 80%;
    padding: 0.95rem;
    border: none;
    border-radius: 7px;
    background-color: var(--gray-color);
    font-family: var(--font-base);
    color: var(--black-color);
    outline: none;
    margin-bottom: 1.4rem;
  }

  @media screen and(max-width: 768px) {
    flex-direction: column;
    input {
      width: 100%;
    }
  }
  .app__auth-button {
    // display: flex;
    // background: red;
    width: 60%;
    button {
      width: 100%;
      padding: 1rem;
      border-radius: 0.5rem;
      outline: none;
      border: none;
      cursor: pointer;
      margin: 1rem auto;
      font-weight: 500;
      color: var(--white-color);
      background-color: var(--black-color);
    }

    p {
      cursor: pointer;
      color: var(--white-color);
    }
  }
}

.app__auth-motion {
  width: 100%;
}
