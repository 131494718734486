.Rune {
  margin: 2rem;
  // background-color: red;
}

.slot {
  position: relative;
  z-index: 2;
  display: block;
  margin-bottom: 35px;
  padding-left: 65px;
  min-height: 54px;
  // background-color: red;

  p {
    color: var(--white-color);
    text-align: center;
    // background-color: green;
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 20%;
    // padding: 1rem;
  }
}

.slot-row {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.7rem 0;
}

.slot-row-second {
  // background-color: red;
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 0.7rem 0;
  width: 200px;
}

.slot-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 54px;
  height: 54px;
  box-sizing: border-box;
  border-radius: 100%;
  background: var(--gray-color);
  border: 2px solid #2b3447;
  // box-shadow: inset 0 0 1px rgb(0 0 0);
  transition: 300ms;
  border-radius: 100%;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
    // transform: scale(1.7);
  }
}

.sp {
  img {
    transform: scale(1.7);
  }
}

.slot-rune {
  cursor: pointer;
  width: 52px;
  height: 52px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 2px solid #2b3447;

  img {
    width: 100%;
    height: 100%;
    // transform: scale(1.7);
  }
}

.slots-slots-slots {
  // background-color: red;
  display: flex;

  .bold-text {
    margin: 0 1rem;
  }
}

.shard-row {
  margin-top: 20px;
  position: relative;
  z-index: 2;
  display: block;
  margin-bottom: 35px;
  padding-left: 65px;
  min-height: 54px;
}

.shard {
  position: relative;
  z-index: 2;
  display: block;
  margin-bottom: 5px;
  padding-left: 65px;
  min-height: 54px;
}

.shard-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 100%;
  background: var(--gray-color);
  border: 2px solid #2b3447;
  // box-shadow: inset 0 0 1px rgb(0 0 0);
  transition: 300ms;
  border-radius: 100%;
  cursor: pointer;
  margin: 0 0.5rem;

  img {
    width: 50%;
    height: 50%;
    // transform: scale(1.7);
  }
}

.slot-shard {
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  vertical-align: top;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.3rem;

  img {
    width: 50%;
    // transform: scale(1.7);
  }
}

.heart {
  border-color: #186948;
  background: radial-gradient(
      ellipse at center,
      rgba(24, 105, 72, 0.75) 0%,
      rgba(24, 105, 72, 0) 100%
    ),
    radial-gradient(ellipse at center, #09111f 0%, #09111f 100%);
}
.diamond {
  background: radial-gradient(
      ellipse at center,
      rgba(90, 51, 178, 0.75) 0%,
      rgba(90, 51, 178, 0) 100%
    ),
    radial-gradient(ellipse at center, #09111f 0%, #09111f 100%);
  border-color: #5a33b2;
}

.axe {
  border-color: #a28541;
  background: radial-gradient(
      ellipse at center,
      rgba(162, 133, 65, 0.75) 0%,
      rgba(162, 133, 65, 0) 100%
    ),
    radial-gradient(ellipse at center, #09111f 0%, #09111f 100%);
}

.time {
  border-color: #a4a198;
  background: radial-gradient(
      ellipse at center,
      rgba(164, 161, 152, 0.75) 0%,
      rgba(164, 161, 152, 0) 100%
    ),
    radial-gradient(ellipse at center, #09111f 0%, #09111f 100%);
}
.shield {
  border-color: #a44d43;
  background: radial-gradient(
      ellipse at center,
      rgba(164, 77, 67, 0.75) 0%,
      rgba(164, 77, 67, 0) 100%
    ),
    radial-gradient(ellipse at center, #09111f 0%, #09111f 100%);
}
.circle {
  border-color: #8a6294;
  background: radial-gradient(
      ellipse at center,
      rgba(138, 98, 148, 0.75) 0%,
      rgba(138, 98, 148, 0) 100%
    ),
    radial-gradient(ellipse at center, #09111f 0%, #09111f 100%);
}

.runes-shards {
  margin: 3rem 0.15rem;
}

.Rune-header-rune {
  display: flex;

  .Rune-h2 {
    color: var(--gray-color);
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0 2rem;
  }
}
