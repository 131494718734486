.Comment {
  background-color: var(--gray-color);
  padding: 1rem;
  width: 80%;
  margin: 1rem auto;
  // border-radius: 10px;
  color: var(--primary-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    padding: 0.5rem;
    text-align: center;
  }
  .Comment-delete {
    button {
      // border-radius: 10px;
      outline: none;
      border: none;
      background-color: var(--black-color);
      color: white;
      padding: 0.5rem;
      cursor: pointer;
      width: 100% !important;
      margin: 0 0.5rem;
    }
  }
}
